import React, { useEffect, useState } from 'react';
import { serializeFilename, serializeRequest } from './serializer';
import BookingSearchForm from './booking-search-form';
import apiMethods from 'core/methods';
import TablePages from 'components/table-pages';
import { columns } from './bookings-table-settings';
import usePages from 'components/use-pages';
import $auth from 'stores/auth';

const BookingSearchPage = ({
    bookingSearchForm,
    isOfflineBookings,
    agencyId,
    agentId,
    additionalColumns,
    TableWrapper = React.Fragment,
}) => {
    const [formValues, setFormValues] = useState(null);
    const [page, setPage] = usePages();
    const isPermittedBookingManagement = $auth.permitted('BookingManagement');

    useEffect(() => {
        bookingSearchForm.validateFields().then(setFormValues);
    }, [isOfflineBookings]);

    const onSubmit = (values) => {
        setPage(1);
        setFormValues(values);
    };

    return (
        <>
            <BookingSearchForm
                bookingSearchForm={bookingSearchForm}
                onSubmit={onSubmit}
                isOfflineBookings={isOfflineBookings}
                isAgencyOrAgent={Boolean(agencyId || agentId)}
            />
            <TableWrapper>
                <TablePages
                    columns={(data) => [
                        ...columns(data),
                        ...(additionalColumns ? additionalColumns(data) : []),
                    ]}
                    formValues={formValues}
                    route={{ url: apiMethods.bookingsList }}
                    downloadRoute={
                        isPermittedBookingManagement ? { url: apiMethods.bookingsListReportDownload } : null
                    }
                    serializeRequest={(values, ...rest) =>
                        serializeRequest(
                            {
                                ...values,
                                ...(isOfflineBookings ? { isOfflineOnly: isOfflineBookings } : {}),
                                ...(agencyId ? { agencies: [agencyId] } : {}),
                                ...(agentId ? { agentId } : {}),
                            },
                            ...rest
                        )
                    }
                    serializeFilename={serializeFilename}
                    page={page}
                    setPage={setPage}
                />
            </TableWrapper>
        </>
    );
};

export default BookingSearchPage;
