import React, { useEffect, useState } from 'react';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Badge, Menu, PageHeader } from 'antd';
import { API, useTitle } from 'htcore';
import AgenciesListPage from './agencies/agency-search';
import AgencyCreatePage from './create/agency-create';
import AgentPage from './agent/agent';
import AgencyPage from './agency';
import AgenciesRequestsList from './requests/requests-list';
import AgenciesRequestItem from './requests/request-item';
import $auth from 'stores/auth';
import apiMethods from '../../core/methods';

const RequestsTab = () => {
    const [count, setCount] = useState();

    useEffect(() => {
        API.get({
            url: apiMethods.agencyRequestsAwaiting,
            success: (result) => {
                setCount(result);
            },
        });
    }, []);

    return (
        <Link to="./requests">
            <Badge count={count} overflowCount={999} color="orange">
                <span style={{ paddingRight: count ? 20 : 0 }}>Registration Requests</span>
            </Badge>
        </Link>
    );
};

const allNavigationItems = [
    { label: <Link to="./list">Agencies List</Link>, key: 'list' },
    { label: <RequestsTab />, key: 'requests', permissions: ['AgencyVerification'] },
    { label: <Link to="./create">Create New Agency</Link>, key: 'create', permissions: ['AddAgency'] },
];

const AgenciesRouterPage = observer(({ agencySearchForm, registrationRequestSearchForm }) => {
    const location = useLocation();

    const navigationItems = allNavigationItems
        .filter((item) => $auth.permitted(item.permissions))
        .map((item) => {
            const { permissions, ...props } = item;
            return props;
        });

    return (
        <>
            {['list', 'create', 'requests'].includes(location.pathname.split('/')[2]) && (
                <>
                    <PageHeader title="Agencies" />
                    <Menu
                        mode="horizontal"
                        items={navigationItems}
                        selectedKeys={location.pathname.split('/')[2] || ''}
                    />
                </>
            )}
            <Routes>
                <Route path="/list" element={<AgenciesListPage agencySearchForm={agencySearchForm} />} />
                <Route path="/create" element={<AgencyCreatePage />} />
                <Route path="/:id/agents/:agentId/*" element={<AgentPage />} />
                <Route path="/:id/*" element={<AgencyPage />} />
                <Route
                    path="/requests"
                    element={
                        <AgenciesRequestsList registrationRequestSearchForm={registrationRequestSearchForm} />
                    }
                />
                <Route path="/requests/:id" element={<AgenciesRequestItem />} />
            </Routes>
        </>
    );
});

export default AgenciesRouterPage;
