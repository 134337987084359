import { date } from 'legacy';
import { BOOKING_STATUS } from 'htcore/enum';
import { PAYMENT_TYPE_SUPPLIERS } from '../../components/selectors/payment-type-selector';

const safeString = (val) => val.replace(/'/g, "''");

export const serializeRequest = (values, top, skip, isItn, isService) => {
    const filters = [];
    const dateFormatter = isService ? date.format.apiDateOnly : date.format.api;

    if (values.isActionRequired) {
        values.statuses = [
            BOOKING_STATUS.Pending,
            BOOKING_STATUS.ManualCorrectionNeeded,
            BOOKING_STATUS.Rejected,
            BOOKING_STATUS.PendingCancellation,
        ];
    }

    if (values.isOfflineOnly) {
        filters.push('isOffline eq true');
    }

    if (values.isNoHotelConfirmationCode) {
        filters.push(`(hotelConfirmationCode eq '' or hotelConfirmationCode eq null)`);
    }

    if (values.rangeCreation) {
        filters.push(
            `created ge ${date.format.api(values.rangeCreation[0])} and created lt ${date.format.api(
                date.addDay(values.rangeCreation[1], 1)
            )}`
        );
    }

    if (values.rangeDeadline) {
        filters.push(
            `deadlineDate ge ${date.format.api(
                values.rangeDeadline[0]
            )} and deadlineDate lt ${date.format.api(date.addDay(values.rangeDeadline[1], 1))}`
        );
    }

    if (values.rangeCheckIn) {
        filters.push(
            `checkinDate ge ${dateFormatter(values.rangeCheckIn[0])} and checkinDate lt ${dateFormatter(
                date.addDay(values.rangeCheckIn[1], 1)
            )}`
        );
    }

    if (values.rangeCheckOut) {
        filters.push(
            `checkoutDate ge ${dateFormatter(values.rangeCheckOut[0])} and checkoutDate lt ${dateFormatter(
                date.addDay(values.rangeCheckOut[1], 1)
            )}`
        );
    }

    if (values.cancellationDate) {
        filters.push(
            `cancellationDate ge ${date.format.api(
                values.cancellationDate[0]
            )} and cancellationDate lt ${date.format.api(date.addDay(values.cancellationDate[1], 1))}`
        );
    }

    if (values.statuses?.length) {
        filters.push('(' + values.statuses.map((status) => `status eq '${status}'`).join(' or ') + ')');
    }

    if (values.paymentTypes?.length) {
        filters.push(
            '(' + values.paymentTypes.map((status) => `paymentType eq '${status}'`).join(' or ') + ')'
        );
    }

    if (values.paymentStatuses?.length) {
        filters.push(
            '(' +
                values.paymentStatuses
                    .map((paymentStatus) => `paymentStatus eq '${paymentStatus}'`)
                    .join(' or ') +
                ')'
        );
    }

    if (values.suppliers?.length) {
        filters.push(
            '(' +
                values.suppliers
                    .map(
                        (supplier) =>
                            `supplierCode eq '${supplier}' ${
                                PAYMENT_TYPE_SUPPLIERS.includes(supplier) && values.paymentType
                                    ? `and paymentTypeWithAccommodation eq '${values.paymentType}'`
                                    : ''
                            }`
                    )
                    .join(' or ') +
                ')'
        );
    }

    if (values.agencies?.length) {
        filters.push('(' + values.agencies.map((agency) => `agencyId eq ${agency}`).join(' or ') + ')');
    }

    if (values.agentId) {
        filters.push(`agentId eq ${values.agentId}`);
    }

    if (values.htId) {
        filters.push(`htId eq '${values.htId}'`);
    }

    if (values.mainPassengerName) {
        filters.push(
            `contains(tolower(mainPassengerName),tolower('${safeString(values.mainPassengerName)}'))`
        );
    }

    if (values.creationCountry) {
        filters.push(`contains(tolower(country),tolower('${safeString(values.creationCountry)}'))`);
    }

    if (values.accountManager) {
        filters.push(`accountManagerId eq ${values.accountManager}`);
    }

    if (values.supplierReferenceCode) {
        filters.push(
            `contains(tolower(supplierReferenceCode),tolower('${safeString(values.supplierReferenceCode)}'))`
        );
    }

    if (values.marketId?.length) {
        filters.push('(' + values.marketId.map((market) => `MarketId eq ${market}`).join(' or ') + ')');
    }

    if (isItn) {
        filters.push('itineraryBookingsCount gt 1');
    }

    if (values.adminId) {
        filters.push(`adminId eq ${values.adminId}`);
    }

    if (values.serviceName) {
        filters.push(`ServiceName eq '${values.serviceName}'`);
    }

    if (values.serviceType) {
        filters.push(`serviceType eq '${values.serviceType}'`);
    }

    return {
        ...(filters.length
            ? {
                  $filter: filters.join(' and '),
              }
            : {}),
        $orderBy: 'Created desc',
        ...(top && skip !== undefined
            ? {
                  $top: top,
                  $skip: skip,
              }
            : {}),
    };
};

export const serializeFilename = (values) => {
    const fileName = ['All-Bookings'];
    const dateFormatter = date.format.apiDateOnly;

    if (values.rangeCreation) {
        fileName.push(
            'Creation',
            dateFormatter(values.rangeCreation[0]),
            dateFormatter(values.rangeCreation[1])
        );
    }

    if (values.rangeDeadline) {
        fileName.push(
            'Deadline',
            dateFormatter(values.rangeDeadline[0]),
            dateFormatter(values.rangeDeadline[1])
        );
    }

    if (values.rangeCheckIn) {
        fileName.push(
            'Check-in',
            dateFormatter(values.rangeCheckIn[0]),
            dateFormatter(values.rangeCheckIn[1])
        );
    }

    if (values.rangeCheckOut) {
        fileName.push(
            'Check-out',
            dateFormatter(values.rangeCheckOut[0]),
            dateFormatter(values.rangeCheckOut[1])
        );
    }

    return fileName.join('-');
};
