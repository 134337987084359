import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Form } from 'antd';
import MainPage from 'pages/cabinet/main';
import NothingFoundPage from 'common/nothing-found';
import AgenciesRouterPage from 'pages/agency/agencies-router';
import CreatePaymentLinkPage from 'pages/paymentlinks/create';
import AdministratorsPage from 'pages/administrator/administrators';
import MapperIndexPage from 'pages/mapper/mapper-index';
import MarkupsPage from 'pages/markups/markups';
import RateComparisonPage from 'pages/rate-comparison/rate-comparison';
import BookingPage from 'pages/bookings/booking';
import BookingsPage from 'pages/bookings/bookings';
import ReportsPage from 'pages/reports/reports';
import AdministratorRolesPage from 'pages/roles/roles-index';
import SuppliersPage from 'pages/suppliers/suppliers-index';
import NotificationSettingsPage from 'pages/cabinet/notification-settings';
import RateManagementPage from 'pages/conversion-management/rate-management/rate-management';
import ConversionFeesPage from 'pages/conversion-management/conversion-fees/conversion-fees';
import BankDetailsIndex from 'pages/bank-details/bank-details-index';
import NotificationsPage from 'pages/notifications/notifications';
import OfflineBookings from '../pages/bookings/offline-bookings/offline-bookings';
import DestinationMappingPage from '../pages/destination-mapping/destonation-mapping-page';

const RoutesPage = () => {
    // todo: refactor global form hook
    const [bookingSearchForm] = Form.useForm();
    const [itinerarySearchForm] = Form.useForm();
    const [serviceSearchForm] = Form.useForm();
    const [accommodationSearchForm] = Form.useForm();
    const [agencySearchForm] = Form.useForm();
    const [registrationRequestSearchForm] = Form.useForm();

    return (
        <Routes>
            <Route path="/" element={<MainPage />} />
            <Route
                path="/agency/*"
                element={
                    <AgenciesRouterPage
                        agencySearchForm={agencySearchForm}
                        registrationRequestSearchForm={registrationRequestSearchForm}
                    />
                }
            />
            <Route path="/booking/:refCode" element={<BookingPage />} />
            <Route path="/payment-links/*" element={<CreatePaymentLinkPage />} />
            <Route path="/reports/*" element={<ReportsPage />} />
            <Route path="/administrators/*" element={<AdministratorsPage />} />
            <Route path="/markups/*" element={<MarkupsPage />} />
            <Route path="/rate-comparison" element={<RateComparisonPage />} />
            <Route path="/administrator-roles/*" element={<AdministratorRolesPage />} />
            <Route path="/bank-details/*" element={<BankDetailsIndex />} />
            <Route path="/rate-management" element={<RateManagementPage />} />
            <Route path="/conversion-fees/*" element={<ConversionFeesPage />} />
            <Route path="/notifications/*" element={<NotificationsPage />} />
            <Route path="/suppliers/*" element={<SuppliersPage />} />
            <Route
                path="/bookings/*"
                element={
                    <BookingsPage
                        bookingSearchForm={bookingSearchForm}
                        itinerarySearchForm={itinerarySearchForm}
                        serviceSearchForm={serviceSearchForm}
                    />
                }
            />
            <Route
                path="/offline-bookings/*"
                element={
                    <OfflineBookings
                        bookingSearchForm={bookingSearchForm}
                        serviceSearchForm={serviceSearchForm}
                    />
                }
            />
            <Route
                path="/mapper/*"
                element={<MapperIndexPage accommodationSearchForm={accommodationSearchForm} />}
            />
            <Route path="/destination-mapping/*" element={<DestinationMappingPage />} />
            <Route
                path="/settings/*"
                element={<NotificationSettingsPage accommodationSearchForm={accommodationSearchForm} />}
            />
            <Route path="*" element={<NothingFoundPage />} />
        </Routes>
    );
};

export default RoutesPage;
