import React from 'react';
import { Link, Route, Routes, useLocation } from 'react-router-dom';
import FindBooking from './tabs/find/find-booking';
import BookingSearchPage from './booking-search';
import BookingsItinerary from './tabs/itinerary/bookings-itinerary';
import AttentionRequiredBookings from './tabs/attention-required/attention-required-bookings';
import { Menu, PageHeader } from 'antd';
import { useTitle } from 'htcore';
import BookingsService from './tabs/service/bookings-service';

const items = [
    { label: <Link to="./">All Bookings</Link>, key: '' },
    { label: <Link to="./service">Service Bookings</Link>, key: 'service' },
    { label: <Link to="./itinerary">Itinerary Bookings</Link>, key: 'itinerary' },
    { label: <Link to="./find">Booking by Reference Code</Link>, key: 'find' },
    { label: <Link to="./attention">Attention Needed</Link>, key: 'attention' },
];

const Bookings = ({ bookingSearchForm, itinerarySearchForm, serviceSearchForm }) => {
    useTitle('Bookings');

    const location = useLocation();
    return (
        <>
            <PageHeader title="Booking Management" />
            <Menu mode="horizontal" items={items} selectedKeys={location.pathname.split('/')[2] || ''} />
            <Routes>
                <Route path="/" element={<BookingSearchPage bookingSearchForm={bookingSearchForm} />} />
                <Route path="/service" element={<BookingsService searchForm={serviceSearchForm} />} />
                <Route path="/itinerary" element={<BookingsItinerary searchForm={itinerarySearchForm} />} />
                <Route path="/find" element={<FindBooking />} />
                <Route path="/attention" element={<AttentionRequiredBookings />} />
            </Routes>
        </>
    );
};

export default Bookings;
